.primary-button {
    background-color: #d83a3a;
    border: none;
    border-radius: 50px;
    color: white;
    padding: 14px 30px;
    cursor: pointer;
    transition: background-color 300ms;
    font-weight: bold;
    font-size: 17px;
}

.primary-button:hover {
    background-color: rgb(180, 22, 22);
}

.secondary-button {
    background-color: #d83a3a;
    padding: 10px 18px;
    border-radius: 100px;
    cursor: pointer;
    text-align: center;
    transition: background-color 300ms;
    font-size: 17px;
}

.secondary-button:hover {
    background-color: #967BA8;
}

.black-button {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px 18px;
    border-radius: 100px;
    cursor: pointer;
    text-align: center;
    transition: background-color 300ms;
    font-size: 15px;
}

.black-button:hover {
    background-color: #000000;
}