.active-link-sidebar {
  color: rgb(181, 21, 181);
  /* Colore per il link attivo */
}

.inactive-link-sidebar {
  color: black;
  /* Colore per il link inattivo */
}
.inactive-link-sidebar2 {
  color: rgb(104, 104, 104);
  /* Colore per il link inattivo */
}

.App {
  background-color: white;
}

.animate {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}